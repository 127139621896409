/**
 * @file 新闻的样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-user-list {
    &-group {
        margin: -10px;
    }

    &-wrap {
        overflow: hidden;
        margin-top: 20px;
    }

    &-item-wrap {
        display: inline-block;
        padding: 10px;
        width: 50%;
    }

    &-item {
        background-color: #e0e0e0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
        height: 0;
        padding-bottom: 40.90625%;
        vertical-align: top;

        > img {
            opacity: 0;
            width: 100%;
        }
    }
}
