/**
 * @file paddle 首页样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file 公用样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../components/quick-start/style/~highlight.js/styles/github-gist.css';
html,
body {
  min-width: 300px;
}
.paddle-context-h1 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  margin-top: 30px;
}
.paddle-context-h3 {
  color: #000;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 10px;
}
.paddle-context-h3 a {
  color: #2932e1;
  margin-left: 8px;
}
.clear-both:after {
  content: "";
  clear: both;
  display: block;
}
.vertical-middle {
  text-align: center;
}
.vertical-middle > * {
  display: inline-block;
  vertical-align: middle;
}
.vertical-middle:after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
/**
 * @file 顶部导航栏样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
* @file 顶部导航栏搜索框样式
* @author FengGuang(fengguang01@baidu.com)
*/
.paddle-header-search {
  display: inline-block;
  width: 100%;
}
.paddle-header-search .ant-select-selection__clear {
  display: none;
  right: 4px;
}
.paddle-header-search-wrap {
  text-align: right;
}
.paddle-header-search .ant-select-selection {
  background-color: transparent;
}
.paddle-header-search.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  line-height: 22px;
}
.paddle-header-search.ant-select-auto-complete.ant-select .ant-input {
  height: 22px;
}
.paddle-header-search .ant-input {
  border-radius: 999999px;
  border-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  height: 22px;
  margin-left: 4px;
  padding-right: 0;
}
.paddle-header-search .ant-input:not(:focus) {
  background-color: transparent;
}
.paddle-header-search .ant-input:focus {
  background-color: #1527c2;
}
.paddle-header-search .ant-input:hover,
.paddle-header-search .ant-input:focus {
  border-color: rgba(255, 255, 255, 0.5);
}
.paddle-header-search .ant-input::-moz-placeholder {
  color: #6d78d3;
  font-size: 12px;
}
.paddle-header-search .ant-input:-ms-input-placeholder {
  color: #6d78d3;
  font-size: 12px;
}
.paddle-header-search .ant-input::-webkit-input-placeholder {
  color: #6d78d3;
  font-size: 12px;
}
.paddle-header-search .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: rgba(255, 255, 255, 0.5);
}
.paddle-header-search .ant-select-selection__clear {
  background: transparent;
}
.paddle-header-search .anticon {
  color: #fff;
}
.paddle-header-search .anticon.ant-select-clear-icon {
  background: #fff;
  color: #5a68d4;
  border-radius: 999999px;
}
.paddle-header-search-input .ant-input-prefix {
  background-color: transparent;
  height: auto;
}
.paddle-header-search-input-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../../components/header/images/search.png);
  display: inline-block;
  height: 12px;
  width: 12px;
}
.paddle-header-search-drop {
  width: 300px;
}
.paddle-header-search-drop .ant-select-dropdown-menu {
  max-height: none;
}
.paddle-header-search-drop-item-divider {
  margin: 5px 20px 2px 20px;
  min-width: unset;
  width: auto;
}
.paddle-header-search-drop-item,
.paddle-header-search-drop-item-more {
  line-height: 40px;
  padding: 0 20px;
}
.paddle-header-search-drop-item-more {
  font-weight: 400;
  cursor: pointer;
}
.paddle-header-search-drop-item-type {
  float: right;
}
.paddle-header {
  height: 50px;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1200px;
  padding: 14px 0;
}
.paddle-header-wrap {
  left: 0;
  min-width: 300px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}
.paddle-header-background {
  background-color: #1527c2;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.paddle-header a {
  text-decoration: none;
}
.paddle-header-logo-item {
  display: inline-block;
  height: 50px;
  overflow: hidden;
}
.paddle-header-logo-wrap {
  display: inline-block;
  height: 22px;
  margin: 0 19px;
  overflow: hidden;
}
.paddle-header-logo {
  height: 22px;
  width: 66px;
}
.paddle-header .paddle-header-menu-icon {
  background-image: url(../../components/header/images/menu.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 20px;
  margin: 0 20px 0 18px;
  vertical-align: middle;
  z-index: 1;
}
.paddle-header .ant-menu {
  background: transparent;
  color: #fff;
  font-weight: 400;
}
.paddle-header .ant-menu-item-group {
  padding: 14px 0;
}
.paddle-header .ant-menu-item-group-title {
  line-height: 40px;
  padding: 0 20px;
}
.paddle-header .ant-menu > .ant-menu-item-divider {
  margin: 0 20px;
}
.paddle-header .ant-menu > .ant-menu-item,
.paddle-header .ant-menu > .ant-menu-submenu {
  margin: 0 15px;
  padding: 0;
}
.paddle-header .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.paddle-header .ant-menu-item:hover,
.paddle-header .ant-menu-item-active,
.paddle-header .ant-menu:not(.ant-menu-inline) .paddle-header .ant-menu-submenu-open,
.paddle-header .ant-menu-submenu-active,
.paddle-header .ant-menu-submenu-title:hover {
  color: #fff;
}
.paddle-header .ant-menu-horizontal {
  border-bottom: none;
  line-height: 28px;
}
.paddle-header .ant-menu-horizontal > .ant-menu-item,
.paddle-header .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 3px solid transparent;
}
.paddle-header .ant-menu-horizontal > .ant-menu-item:hover,
.paddle-header .ant-menu-horizontal > .ant-menu-submenu:hover,
.paddle-header .ant-menu-horizontal > .ant-menu-item-active,
.paddle-header .ant-menu-horizontal > .ant-menu-submenu-active,
.paddle-header .ant-menu-horizontal > .ant-menu-item-open,
.paddle-header .ant-menu-horizontal > .ant-menu-submenu-open,
.paddle-header .ant-menu-horizontal > .ant-menu-item-selected,
.paddle-header .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #fff;
  border-bottom: 3px solid rgba(255, 255, 255, 0.3);
  font-weight: 500;
}
.paddle-header .ant-menu-horizontal > .ant-menu-item > a {
  color: #fff;
}
.paddle-header .ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #fff;
}
.paddle-header .ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #fff;
}
.paddle-header .ant-menu-item-group-list .ant-menu-item,
.paddle-header .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 20px;
  margin: 0;
}
.paddle-header-right {
  float: right;
}
.paddle-header-search-wrap {
  display: inline-block;
  width: 156px;
  vertical-align: top;
}
.paddle-header-links {
  color: #fff;
  display: inline-block;
  line-height: 50px;
  margin-left: 10px;
}
.paddle-header-links-item {
  display: inline-block;
  padding: 0 10px;
}
.paddle-header-links-link {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.paddle-header-links-link:hover {
  color: #fff;
}
.paddle-header-drawer .ant-drawer-body {
  padding: 20px 0 20px 20px;
}
.paddle-header-drawer .ant-drawer-content-wrapper {
  width: 244px;
}
.paddle-header-drawer-close {
  position: absolute;
  z-index: 10;
  display: block;
  width: 56px;
  height: 56px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-rendering: auto;
  top: 0;
  right: 0;
}
.paddle-header-drawer-close-icon {
  background-image: url(../../components/header/images/close.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 14px;
  width: 14px;
}
.paddle-header-drawer-logo-wrap {
  margin-top: 40px;
  width: 80px;
}
.paddle-header-drawer-logo {
  width: 100%;
}
.paddle-header-drawer-divider {
  border-top: 1px solid #000;
  margin-top: 10px;
}
.paddle-header-drawer-menu {
  border-right: none;
  font-size: 14px;
  margin-right: -20px;
  margin-top: 10px;
}
.paddle-header-drawer-menu .ant-menu-item-divider {
  display: none;
}
.paddle-header-drawer-menu .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 0;
}
.paddle-header-drawer-menu .ant-menu-item,
.paddle-header-drawer-menu .ant-menu-submenu-title,
.paddle-header-drawer-menu.ant-menu-inline .ant-menu-item,
.paddle-header-drawer-menu.ant-menu-inline .ant-menu-submenu-title {
  margin: 0;
}
.paddle-header-drawer-menu.ant-menu .ant-menu-item-selected {
  background-color: transparent;
}
.paddle-header-drawer-menu.ant-menu-inline .ant-menu-item::after {
  content: none;
}
.paddle-header-drawer-menu .ant-menu.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  margin-left: 14px;
}
.paddle-header-drawer-menu .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item {
  margin-left: 14px;
}
.paddle-header-drawer-menu .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item:not(.ant-menu-item-selected),
.paddle-header-drawer-menu .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item:not(.ant-menu-item-selected) > a {
  color: #000;
}
.paddle-header-drawer-menu > .ant-menu-item,
.paddle-header-drawer-menu > .ant-menu-item > a,
.paddle-header-drawer-menu > .ant-menu-submenu > .ant-menu-submenu-title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.paddle-header-drawer-menu .ant-menu-item:hover,
.paddle-header-drawer-menu .ant-menu-item-selected,
.paddle-header-drawer-menu .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.paddle-header-drawer-menu .ant-menu-submenu-selected,
.paddle-header-drawer-menu .ant-menu-submenu-title:hover {
  color: #2932e1;
}
/**
 * @file 页脚样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-footer {
  background: #f5f5f5;
  color: #000;
  display: block;
  margin-top: 60px;
  min-height: 403px;
}
.paddle-footer-container {
  max-width: 768px;
  min-height: 353px;
  margin: 0 auto;
  overflow: hidden;
  padding: 20px;
}
.paddle-footer-group {
  margin-top: 20px;
  vertical-align: top;
  overflow: hidden;
}
.paddle-footer-group-title {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.paddle-footer-item-list {
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 5px;
  margin: 0 -15px;
}
.paddle-footer-item {
  color: #7b7b7b;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  margin: 0 15px;
}
.paddle-footer-item a {
  color: #7b7b7b;
}
.paddle-footer-qr-wrap {
  border-top: 1px solid #dcdcdc;
  margin: 40px 0 -20px 0;
  padding: 26px 0 6px 0;
  text-align: center;
}
.paddle-footer-qr {
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
  margin: 0 auto;
  overflow: hidden;
  width: 100px;
}
.paddle-footer-qr-img {
  opacity: 0;
  width: 100%;
}
.paddle-footer-qr-item {
  margin-bottom: 20px;
}
.paddle-footer-qr-title {
  color: #7e7e7e;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
  text-align: center;
}
.paddle-footer-qr-subtitle {
  font-size: 20px;
  transform: scale(0.5);
}
.paddle-footer-bottom {
  background: #eaeaea;
  color: #7b7b7b;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
/**
 * @file 快速开始组件的样式
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
/**
 * @file Markdown 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.markdown-body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: #24292e;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
}
.markdown-body .pl-c {
  color: #6a737d;
}
.markdown-body .pl-c1,
.markdown-body .pl-s .pl-v {
  color: #005cc5;
}
.markdown-body .pl-e,
.markdown-body .pl-en {
  color: #6f42c1;
}
.markdown-body .pl-smi,
.markdown-body .pl-s .pl-s1 {
  color: #24292e;
}
.markdown-body .pl-ent {
  color: #22863a;
}
.markdown-body .pl-k {
  color: #d73a49;
}
.markdown-body .pl-s,
.markdown-body .pl-pds,
.markdown-body .pl-s .pl-pse .pl-s1,
.markdown-body .pl-sr,
.markdown-body .pl-sr .pl-cce,
.markdown-body .pl-sr .pl-sre,
.markdown-body .pl-sr .pl-sra {
  color: #032f62;
}
.markdown-body .pl-v,
.markdown-body .pl-smw {
  color: #e36209;
}
.markdown-body .pl-bu {
  color: #b31d28;
}
.markdown-body .pl-ii {
  color: #fafbfc;
  background-color: #b31d28;
}
.markdown-body .pl-c2 {
  color: #fafbfc;
  background-color: #d73a49;
}
.markdown-body .pl-c2::before {
  content: "^M";
}
.markdown-body .pl-sr .pl-cce {
  font-weight: bold;
  color: #22863a;
}
.markdown-body .pl-ml {
  color: #735c0f;
}
.markdown-body .pl-mh,
.markdown-body .pl-mh .pl-en,
.markdown-body .pl-ms {
  font-weight: bold;
  color: #005cc5;
}
.markdown-body .pl-mi {
  font-style: italic;
  color: #24292e;
}
.markdown-body .pl-mb {
  font-weight: bold;
  color: #24292e;
}
.markdown-body .pl-md {
  color: #b31d28;
  background-color: #ffeef0;
}
.markdown-body .pl-mi1 {
  color: #22863a;
  background-color: #f0fff4;
}
.markdown-body .pl-mc {
  color: #e36209;
  background-color: #ffebda;
}
.markdown-body .pl-mi2 {
  color: #f6f8fa;
  background-color: #005cc5;
}
.markdown-body .pl-mdr {
  font-weight: bold;
  color: #6f42c1;
}
.markdown-body .pl-ba {
  color: #586069;
}
.markdown-body .pl-sg {
  color: #959da5;
}
.markdown-body .pl-corl {
  text-decoration: underline;
  color: #032f62;
}
.markdown-body .octicon {
  display: inline-block;
  vertical-align: text-top;
  fill: currentColor;
}
.markdown-body a {
  background-color: transparent;
}
.markdown-body a:active,
.markdown-body a:hover {
  outline-width: 0;
}
.markdown-body strong {
  font-weight: inherit;
}
.markdown-body strong {
  font-weight: bolder;
}
.markdown-body h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
.markdown-body img {
  border-style: none;
}
.markdown-body code,
.markdown-body kbd,
.markdown-body pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
.markdown-body hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.markdown-body input {
  font: inherit;
  margin: 0;
}
.markdown-body input {
  overflow: visible;
}
.markdown-body [type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
.markdown-body * {
  box-sizing: border-box;
}
.markdown-body input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.markdown-body a {
  color: #0366d6;
  text-decoration: none;
}
.markdown-body a:hover {
  text-decoration: underline;
}
.markdown-body strong {
  font-weight: 600;
}
.markdown-body hr {
  height: 0;
  margin: 15px 0;
  overflow: hidden;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #dfe2e5;
}
.markdown-body hr::before {
  display: table;
  content: "";
}
.markdown-body hr::after {
  display: table;
  clear: both;
  content: "";
}
.markdown-body table {
  border-spacing: 0;
  border-collapse: collapse;
}
.markdown-body td,
.markdown-body th {
  padding: 0;
}
.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-body h1 {
  font-size: 32px;
  font-weight: 600;
}
.markdown-body h2 {
  font-size: 24px;
  font-weight: 600;
}
.markdown-body h3 {
  font-size: 20px;
  font-weight: 600;
}
.markdown-body h4 {
  font-size: 16px;
  font-weight: 600;
}
.markdown-body h5 {
  font-size: 14px;
  font-weight: 600;
}
.markdown-body h6 {
  font-size: 12px;
  font-weight: 600;
}
.markdown-body p {
  margin-top: 0;
  margin-bottom: 10px;
}
.markdown-body blockquote {
  margin: 0;
}
.markdown-body ul,
.markdown-body ol {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-body ol ol,
.markdown-body ul ol {
  list-style-type: lower-roman;
}
.markdown-body ul ul ol,
.markdown-body ul ol ol,
.markdown-body ol ul ol,
.markdown-body ol ol ol {
  list-style-type: lower-alpha;
}
.markdown-body dd {
  margin-left: 0;
}
.markdown-body code {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 12px;
}
.markdown-body pre {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 12px;
}
.markdown-body .octicon {
  vertical-align: text-bottom;
}
.markdown-body .pl-0 {
  padding-left: 0 !important;
}
.markdown-body .pl-1 {
  padding-left: 4px !important;
}
.markdown-body .pl-2 {
  padding-left: 8px !important;
}
.markdown-body .pl-3 {
  padding-left: 16px !important;
}
.markdown-body .pl-4 {
  padding-left: 24px !important;
}
.markdown-body .pl-5 {
  padding-left: 32px !important;
}
.markdown-body .pl-6 {
  padding-left: 40px !important;
}
.markdown-body::before {
  display: table;
  content: "";
}
.markdown-body::after {
  display: table;
  clear: both;
  content: "";
}
.markdown-body > *:first-child {
  margin-top: 0 !important;
}
.markdown-body > *:last-child {
  margin-bottom: 0 !important;
}
.markdown-body a:not([href]) {
  color: inherit;
  text-decoration: none;
}
.markdown-body .anchor {
  float: left;
  padding-right: 4px;
  margin-left: -20px;
  line-height: 1;
}
.markdown-body .anchor:focus {
  outline: none;
}
.markdown-body p,
.markdown-body blockquote,
.markdown-body ul,
.markdown-body ol,
.markdown-body dl,
.markdown-body table,
.markdown-body pre {
  margin-top: 0;
  margin-bottom: 16px;
}
.markdown-body hr {
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0;
}
.markdown-body blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}
.markdown-body blockquote > :first-child {
  margin-top: 0;
}
.markdown-body blockquote > :last-child {
  margin-bottom: 0;
}
.markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font-size: 11px;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: solid 1px #c6cbd1;
  border-bottom-color: #959da5;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #959da5;
}
.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}
.markdown-body h1 .octicon-link,
.markdown-body h2 .octicon-link,
.markdown-body h3 .octicon-link,
.markdown-body h4 .octicon-link,
.markdown-body h5 .octicon-link,
.markdown-body h6 .octicon-link {
  color: #1b1f23;
  vertical-align: middle;
  visibility: hidden;
}
.markdown-body h1:hover .anchor,
.markdown-body h2:hover .anchor,
.markdown-body h3:hover .anchor,
.markdown-body h4:hover .anchor,
.markdown-body h5:hover .anchor,
.markdown-body h6:hover .anchor {
  text-decoration: none;
}
.markdown-body h1:hover .anchor .octicon-link,
.markdown-body h2:hover .anchor .octicon-link,
.markdown-body h3:hover .anchor .octicon-link,
.markdown-body h4:hover .anchor .octicon-link,
.markdown-body h5:hover .anchor .octicon-link,
.markdown-body h6:hover .anchor .octicon-link {
  visibility: visible;
}
.markdown-body h1 {
  padding-bottom: 0.3em;
  font-size: 2em;
  border-bottom: 1px solid #eaecef;
}
.markdown-body h2 {
  padding-bottom: 0.3em;
  font-size: 1.5em;
  border-bottom: 1px solid #eaecef;
}
.markdown-body h3 {
  font-size: 1.25em;
}
.markdown-body h4 {
  font-size: 1em;
}
.markdown-body h5 {
  font-size: 0.875em;
}
.markdown-body h6 {
  font-size: 0.85em;
  color: #6a737d;
}
.markdown-body ul,
.markdown-body ol {
  padding-left: 2em;
}
.markdown-body ul ul,
.markdown-body ul ol,
.markdown-body ol ol,
.markdown-body ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-body li {
  word-wrap: break-all;
}
.markdown-body li > p {
  margin-top: 16px;
}
.markdown-body li + li {
  margin-top: 0.25em;
}
.markdown-body dl {
  padding: 0;
}
.markdown-body dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}
.markdown-body dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}
.markdown-body table {
  display: block;
  width: 100%;
  overflow: auto;
}
.markdown-body table th {
  font-weight: 600;
}
.markdown-body table th,
.markdown-body table td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}
.markdown-body table tr {
  background-color: #fff;
  border-top: 1px solid #c6cbd1;
}
.markdown-body table tr:nth-child(2n) {
  background-color: #f6f8fa;
}
.markdown-body img {
  max-width: 100%;
  box-sizing: content-box;
  background-color: #fff;
}
.markdown-body img[align=right] {
  padding-left: 20px;
}
.markdown-body img[align=left] {
  padding-right: 20px;
}
.markdown-body code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
}
.markdown-body pre {
  word-wrap: normal;
}
.markdown-body pre > code {
  padding: 0;
  margin: 0;
  font-size: 100%;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}
.markdown-body .highlight {
  margin-bottom: 16px;
}
.markdown-body .highlight pre {
  margin-bottom: 0;
  word-break: normal;
}
.markdown-body .highlight pre,
.markdown-body pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 3px;
}
.markdown-body pre code {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}
.markdown-body .full-commit .btn-outline:not(:disabled):hover {
  color: #005cc5;
  border-color: #005cc5;
}
.markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: solid 1px #d1d5da;
  border-bottom-color: #c6cbd1;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #c6cbd1;
}
.markdown-body :checked + .radio-label {
  position: relative;
  z-index: 1;
  border-color: #0366d6;
}
.markdown-body .task-list-item {
  list-style-type: none;
}
.markdown-body .task-list-item + .task-list-item {
  margin-top: 3px;
}
.markdown-body .task-list-item input {
  margin: 0 0.2em 0.25em -1.6em;
  vertical-align: middle;
}
.markdown-body hr {
  border-bottom-color: #eee;
}
/**
 * @file Markdown 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.markdown-body {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 40px;
  width: auto;
}
.markdown-body code {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  display: inline-block;
  font-size: 100%;
  line-height: 40px;
  padding: 0 16px;
  position: relative;
}
.markdown-body code .copy-btn {
  right: 10px;
  top: 2px;
}
.markdown-body .highlight pre,
.markdown-body pre {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-top: 10px;
  min-width: 0;
  padding: 16px 24px 16px 16px;
  position: relative;
  width: auto;
}
.markdown-body .highlight pre code,
.markdown-body pre code {
  border: 0;
  min-width: 0;
  padding-right: 0;
  width: auto;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.markdown-body .highlight pre .copy-btn,
.markdown-body pre .copy-btn {
  right: 10px;
  top: 18px;
}
.markdown-body .copy-btn {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(../../components/markdown-container/images/markdown-copy.png);
  cursor: pointer;
  float: right;
  display: inline-block;
  height: 12px;
  position: absolute;
  width: 12px;
}
.markdown-body .copy-btn:hover {
  background-image: url(../../components/markdown-container/images/markdown-copy-hover.png);
}
.paddle-quick-start {
  color: #000;
  font-size: 14px;
  margin-top: 20px;
}
.paddle-quick-start-item {
  align-items: center;
  margin-bottom: 20px;
}
.paddle-quick-start-item:last-of-type {
  margin-bottom: 0;
}
.paddle-quick-start-item-title {
  font-weight: 400;
  margin-top: 20px;
}
.paddle-quick-start-item-children {
  margin-top: 10px;
}
.paddle-quick-start-item-children > .ant-btn-group {
  align-items: center;
  display: flex;
  width: 100%;
}
.paddle-quick-start-item-children > .ant-btn-group > * {
  flex: 1 1 0;
}
.paddle-quick-start-item-children .ant-btn {
  padding: 0 5px;
}
.paddle-quick-start-item-children .ant-btn.checked {
  background-color: #2932e1;
  border-color: #2932e1;
  color: #fff;
}
.paddle-quick-start-item-children .ant-btn:active,
.paddle-quick-start-item-children .ant-btn:focus {
  border-color: #2932e1;
  font-weight: 500;
}
.paddle-quick-start-item-children .ant-btn.disabled {
  border-color: #e5e5e5;
  color: #b7b7b7;
}
.paddle-quick-start-result {
  background-color: #f2f2f2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin-top: 30px;
  padding: 20px;
}
.paddle-quick-start .ant-btn {
  color: #000;
  font-size: 12px;
}
/**
 * @file 优势的样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-advantage-group {
  margin-top: 20px;
}
.paddle-advantage-item {
  min-height: 60px;
  margin-bottom: 20px;
}
.paddle-advantage-item:last-of-type {
  margin-bottom: 0;
}
.paddle-advantage-item-title {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.17px;
  margin-bottom: 10px;
}
.paddle-advantage-item-text {
  color: #666;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
}
.paddle-advantage-item-text-ellipsis {
  background: #fff;
  bottom: 0;
  display: inline-block;
  position: absolute;
  right: 0;
}
.paddle-advantage-more-wrap {
  color: #2932e1;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
}
.paddle-advantage-more {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
/**
 * @file 首页banner样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-index-banner {
  min-height: 250px;
  margin: 0 auto;
  max-width: 768px;
  padding: 70px 10px 0 20px;
  position: relative;
}
.paddle-index-banner-wrap {
  background-image: url(./images/banner.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.paddle-index-banner-title {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.6px;
  line-height: 28px;
  margin-bottom: 6px;
}
.paddle-index-banner-text {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1.4px;
  line-height: 20px;
  margin-bottom: 20px;
  opacity: 0.7;
}
@media (min-width: 375px) {
  .paddle-index-banner-text {
    font-size: 14px;
  }
}
@media (min-width: 414px) {
  .paddle-index-banner-text {
    font-size: 15px;
  }
}
.paddle-index-banner-btn-group {
  bottom: 55px;
  left: 0;
  position: absolute;
  right: 0;
}
.paddle-index-banner-btn {
  border-radius: 999999px;
  color: #1434c2;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  letter-spacing: 1px;
  margin-left: 20px;
  width: 100px;
}
/**
 * @file 新闻的样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-news-group {
  margin-top: 20px;
}
.paddle-news-item {
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  min-height: 60px;
  padding: 20px;
}
.paddle-news-item:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: 1px solid #d8d8d8;
}
.paddle-news-item:last-of-type {
  border-bottom: 1px solid #d8d8d8;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 0;
}
.paddle-news-item-title {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  margin-bottom: 10px;
}
.paddle-news-item-text {
  color: #666;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 10px;
}
.paddle-news-item-more-wrap {
  color: #2932e1;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
}
.paddle-news-item-more {
  background-color: transparent;
  border: none;
  color: #2932e1;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
/**
 * @file 新闻的样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-user-list-group {
  margin: -10px;
}
.paddle-user-list-wrap {
  overflow: hidden;
  margin-top: 20px;
}
.paddle-user-list-item-wrap {
  display: inline-block;
  padding: 10px;
  width: 50%;
}
.paddle-user-list-item {
  background-color: #e0e0e0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  height: 0;
  padding-bottom: 40.90625%;
  vertical-align: top;
}
.paddle-user-list-item > img {
  opacity: 0;
  width: 100%;
}
.paddle-index-page-context-h1 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  margin: 30px 0 20px 0;
}
.paddle-index-content {
  max-width: 768px;
  margin: 0 auto;
  overflow: hidden;
}
.paddle-index-content-wrap {
  padding: 0 20px;
  margin-top: -20px;
}
.paddle-index-right {
  display: inline-block;
  float: left;
  max-width: 281px;
  margin-left: 114px;
}
