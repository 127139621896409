/**
* @file 顶部导航栏搜索框样式
* @author FengGuang(fengguang01@baidu.com)
*/


.paddle-header-search {
    display: inline-block;
    width: 100%;

    .ant-select-selection__clear {
        display: none;
        right: 4px;
    }

    &-wrap {
        text-align: right;
    }


    .ant-select-selection {
        background-color: transparent;
    }

    &.ant-select-auto-complete.ant-select {
        .ant-select-selection__rendered {
            line-height: 22px;
        }

        .ant-input {
            height: 22px;
        }
    }

    .ant-input {
        border-radius: 999999px;
        border-color: @search-border-color;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        height: 22px;
        margin-left: 4px;
        padding-right: 0;

        &:not(:focus) {
            background-color: transparent;
        }

        &:focus {
            background-color: #1527c2;
        }

        &:hover,
        &:focus {
            border-color: @search-border-color;
        }
    }

    .ant-input::-moz-placeholder {
        color: rgb(109, 120, 211);
        font-size: 12px;
    }

    .ant-input:-ms-input-placeholder {
        color: rgb(109, 120, 211);
        font-size: 12px;
    }

    .ant-input::-webkit-input-placeholder {
        color: rgb(109, 120, 211);
        font-size: 12px;
    }

    .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
        border-color: @search-border-color;
    }

    .ant-select-selection__clear {
        background: transparent;
    }

    .anticon {
        color: #fff;

        &.ant-select-clear-icon {
            background: #fff;
            color: #5a68d4;
            border-radius: 999999px;
        }
    }

    &-input .ant-input-prefix {
        background-color: transparent;
        height: auto;
    }

    &-input-icon {
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../../images/search.png);
        display: inline-block;
        height: 12px;
        width: 12px;
    }

    &-drop {
        width: 300px;

        .ant-select-dropdown-menu {
            max-height: none;
        }

        &-item-divider {
            margin: 5px 20px 2px 20px;
            min-width: unset;
            width: auto;
        }

        &-item,
        &-item-more {
            line-height: 40px;
            padding: 0 20px;
        }

        &-item-more {
            font-weight: 400;
            cursor: pointer;
        }

        &-item-type {
            float: right;
        }
    }
}
