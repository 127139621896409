/**
 * @file 顶部导航栏样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import './components/header-search/style';

@header-height: 50px;
@footer-distance: 100px;
@search-border-color: rgba(255, 255, 255, .5);

.paddle-header {
    height: @header-height;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1200px;
    padding: 14px 0;

    &-wrap {
        left: 0;
        min-width: 300px;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 999;
    }

    &-background {
        background-color: #1527c2;
        bottom: 0;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }

    a {
        text-decoration: none;
    }

    &-logo-item {
        display: inline-block;
        height: @header-height;
        overflow: hidden;
    }

    &-logo-wrap {
        display: inline-block;
        height: 22px;
        margin: 0 19px;
        overflow: hidden;
    }

    &-logo {
        height: 22px;
        width: 66px;
    }

    .paddle-header-menu-icon {
        background-image: url(images/menu.png);
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        height: 18px;
        width: 20px;
        margin: 0 20px 0 18px;
        vertical-align: middle;
        z-index: 1;
    }

    .ant-menu {
        background: transparent;
        color: #fff;
        font-weight: 400;

        &-item-group {
            padding: 14px 0;
        }

        &-item-group-title {
            line-height: 40px;
            padding: 0 20px;
        }

        > .ant-menu-item-divider {
            margin: 0 20px;
        }

        > .ant-menu-item,
        > .ant-menu-submenu {
            margin: 0 15px;
            padding: 0;
        }

        > .ant-menu-submenu > .ant-menu-submenu-title {
            padding: 0;
        }

        &-item:hover,
        &-item-active,
        &:not(.ant-menu-inline)
        &-submenu-open,
        &-submenu-active,
        &-submenu-title:hover {
            color: #fff;
        }

        &-horizontal {
            border-bottom: none;
            line-height: 28px;

            > .ant-menu-item,
            > .ant-menu-submenu {
                border-bottom: 3px solid transparent;

                &:hover,
                &-active,
                &-open,
                &-selected {
                    color: #fff;
                    border-bottom: 3px solid rgba(255, 255, 255, 0.3);
                    font-weight: 500;
                }
            }

            > .ant-menu-item {
                > a {
                    color: #fff;

                    &:hover {
                        color: #fff;
                    }
                }

                &-selected > a {
                    color: #fff;
                }
            }
        }

        &-item-group-list {
            .ant-menu-item,
            .ant-menu-submenu-title {
                padding: 0 20px;
                margin: 0;
            }
        }
    }

    &-right {
        float: right;
    }

    &-search-wrap {
        display: inline-block;
        width: 156px;
        vertical-align: top;
    }

    &-links {
        color: #fff;
        display: inline-block;
        line-height: @header-height;
        margin-left: 10px;


        &-item {
            display: inline-block;
            padding: 0 10px;
        }

        &-link {
            background-color: transparent;
            border: none;
            color: #fff;
            cursor: pointer;
            display: inline;
            margin: 0;
            padding: 0;
            text-decoration: none;

            &:hover {
                color: #fff;
            }
        }
    }
}

.paddle-header-drawer {
    .ant-drawer-body {
        padding: 20px 0 20px 20px;
    }

    .ant-drawer-content-wrapper {
        width: 244px;
    }

    &-close {
        position: absolute;
        z-index: 10;
        display: block;
        width: 56px;
        height: 56px;
        padding: 0;
        color: rgba(0, 0, 0, 0.45);
        font-weight: 700;
        font-size: 16px;
        font-style: normal;
        line-height: 56px;
        text-align: center;
        text-transform: none;
        text-decoration: none;
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        -webkit-transition: color 0.3s;
        transition: color 0.3s;
        text-rendering: auto;
        top: 0;
        right: 0;
    }

    &-close-icon {
        background-image: url(images/close.png);
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        height: 14px;
        width: 14px;
    }

    &-logo-wrap {
        margin-top: 40px;
        width: 80px;
    }

    &-logo {
        width: 100%;
    }

    &-divider {
        border-top: 1px solid #000;
        margin-top: 10px;
    }
}

.paddle-header-drawer-menu {
    border-right: none;
    font-size: 14px;
    margin-right: -20px;
    margin-top: 10px;

    .ant-menu-item-divider {
        display: none;
    }

    .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
        padding-left: 0;
    }

    .ant-menu-item,
    .ant-menu-submenu-title,
    &.ant-menu-inline .ant-menu-item,
    &.ant-menu-inline .ant-menu-submenu-title {
        margin: 0;
    }

    &.ant-menu .ant-menu-item-selected {
        background-color: transparent;
    }

    &.ant-menu-inline .ant-menu-item::after {
        content: none;
    }

    .ant-menu.ant-menu-sub.ant-menu-inline {
        > .ant-menu-item {
            margin-left: 14px;
        }
    }

    .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item {
        margin-left: 14px;
    }

    .ant-menu-item-group > .ant-menu-item-group-list {
        > .ant-menu-item:not(.ant-menu-item-selected),
        > .ant-menu-item:not(.ant-menu-item-selected) > a {
            color: #000;
        }
    }


    > .ant-menu-item,
    > .ant-menu-item > a,
    > .ant-menu-submenu > .ant-menu-submenu-title {
        color: #000;
        font-size: 16px;
        font-weight: 600;
    }

    .ant-menu-item:hover,
    .ant-menu-item-selected,
    .ant-menu:not(.ant-menu-inline)
    .ant-menu-submenu-open,
    .ant-menu-submenu-selected,
    .ant-menu-submenu-title:hover {
        color: #2932e1;
    }
}
