/**
 * @file Markdown 样式
 * @author FengGuang(fengguang01@baidu.com)
 */


// 自行添加的内容
.markdown-body {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 40px;
    width: auto;

    code {
        background: #fff;
        border: 1px solid #eee;
        border-radius: 8px;
        display: inline-block;
        font-size: 100%;
        line-height: 40px;
        padding: 0 16px;
        position: relative;

        .copy-btn {
            right: 10px;
            top: 2px;
        }
    }

    .highlight pre,
    pre {
        background: #fff;
        border: 1px solid #eee;
        border-radius: 8px;
        margin-top: 10px;
        min-width: 0;
        padding: 16px 24px 16px 16px;
        position: relative;
        width: auto;

        code {
            border: 0;
            min-width: 0;
            padding-right: 0;
            width: auto;
            white-space: -moz-pre-wrap;
            white-space: -o-pre-wrap;
            white-space: pre-wrap;
            word-wrap: break-word;
        }

        .copy-btn {
            right: 10px;
            top: 18px;
        }
    }

    .copy-btn {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-image: url(../../markdown-container/images/markdown-copy.png);
        cursor: pointer;
        float: right;
        display: inline-block;
        height: 12px;
        position: absolute;
        width: 12px;
    }

    .copy-btn:hover {
        background-image: url(../../markdown-container/images/markdown-copy-hover.png);
    }
}
