/**
 * @file 优势的样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-advantage {
    &-group {
        margin-top: 20px;
    }

    &-item {
        min-height: 60px;
        margin-bottom: 20px;

        &-content {
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &-title {
            color: #000;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1.17px;
            margin-bottom: 10px;
        }

        &-text {
            color: #666;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 22px;
            position: relative;

            &-ellipsis {
                background: #fff;
                bottom: 0;
                display: inline-block;
                position: absolute;
                right: 0;
            }
        }
    }

    &-more-wrap {
        color: #2932e1;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
    }

    &-more {
        background-color: transparent;
        border: none;
        //color: #2932e1;
        cursor: pointer;
        display: inline;
        font-weight: 400;
        margin: 0;
        padding: 0;
        text-decoration: none;
    }
}
