/**
 * @file 快速开始组件的样式
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
@import '~highlight.js/styles/github-gist.css';
@import '../../markdown-container/style';
@import './markdown-theme';

.paddle-quick-start {
    color: #000;
    font-size: 14px;
    margin-top: 20px;

    &-content {
    }

    &-item {
        align-items: center;
        margin-bottom: 20px;

        > * {
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &-title {
            font-weight: 400;
            margin-top: 20px;
        }

        &-children {
            margin-top: 10px;

            > .ant-btn-group {
                align-items: center;
                display: flex;
                width: 100%;

                > * {
                    flex: 1 1 0;
                }
            }

            .ant-btn {
                padding: 0 5px;

                &.checked {
                    background-color: #2932e1;
                    border-color: #2932e1;
                    color: #fff;
                }

                &:active, &:focus {
                    border-color: #2932e1;
                    font-weight: 500;
                }

                &.disabled {
                    border-color: #e5e5e5;
                    color: #b7b7b7;
                }
            }

        }
    }

    &-result {
        background-color: #f2f2f2;
        border: 1px solid #d8d8d8;
        border-radius: 4px;
        margin-top: 30px;
        padding: 20px;
    }

    .ant-btn {
        color: #000;
        font-size: 12px;
    }
}
