/**
 * @file 公用样式
 * @author FengGuang(fengguang01@baidu.com)
 */
html,
body {
    min-width: 300px;
}

.paddle-context-h1 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    margin-top: 30px;
}

.paddle-context-h3 {
    color: #000;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 10px;

    a {
        color: #2932e1;
        margin-left: 8px;
    }
}

.clear-both:after {
    content: "";
    clear: both;
    display: block;
}

// 子元水平素垂直居中
.vertical-middle {
    text-align: center;
    > * {
        display: inline-block;
        vertical-align: middle;
    }

    &:after {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
}
