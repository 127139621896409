/**
 * @file 页脚样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-footer {
    background: #f5f5f5;
    color: #000;
    display: block;
    margin-top: 60px;
    min-height: 403px;

    &-container {
        max-width: 768px;
        min-height: 353px;
        margin: 0 auto;
        overflow: hidden;
        padding: 20px;
    }

    &-group {
        margin-top: 20px;
        vertical-align: top;
        overflow: hidden;
    }

    &-group-title {
        color: #000;
        font-size: 14px;
        font-weight: 500;
    }

    &-item-list {
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
        margin-top: 5px;
        margin: 0 -15px;
    }

    &-item {
        color: #7b7b7b;
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        line-height: 36px;
        margin: 0 15px;
    }

    &-item a {
        color: #7b7b7b;
    }

    &-qr-wrap {
        border-top: 1px solid #dcdcdc;
        margin: 40px 0 -20px 0;
        padding: 26px 0 6px 0;
        text-align: center;
    }

    &-qr {
        background-size: contain;
        background-repeat: no-repeat;
        height: 100px;
        margin: 0 auto;
        overflow: hidden;
        width: 100px;

        &-img {
            opacity: 0;
            width: 100%
        }
    }

    &-qr-item {
        margin-bottom: 20px;
    }

    &-qr-title {
        color: #7e7e7e;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 10px;
        text-align: center;
    }

    &-qr-subtitle {
        font-size: 20px;
        transform: scale(.5);
    }

    &-bottom {
        background: #eaeaea;
        color: #7b7b7b;
        font-size: 14px;
        height: 50px;
        line-height: 50px;
        text-align: center;
    }
}

