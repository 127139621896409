/**
 * @file paddle 首页样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../style/global';
@import '../../components/header/style';
@import '../../components/footer/style';
@import '../../components/quick-start/style/index';
@import './components/advantage/style';
@import './components/banner/style';
@import './components/news/style';
@import './components/partner/style';

.paddle-index-page {
    &-context-h1 {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        margin: 30px 0 20px 0;
    }
}

.paddle-index-content {
    max-width: 768px;
    margin: 0 auto;
    overflow: hidden;

    &-wrap {
        padding: 0 20px;
        margin-top: -20px;
    }
}

.paddle-index-left {
}

.paddle-index-right {
    display: inline-block;
    float: left;
    max-width: 281px;
    margin-left: 114px;
}


@primary-color: #2932e1;@link-color: #2932e1;@fill-body: ~"";