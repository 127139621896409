/**
 * @file 新闻的样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-news {
    &-group {
        margin-top: 20px;
    }

    &-item {
        border-left: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
        border-top: 1px solid #d8d8d8;
        min-height: 60px;
        padding: 20px;

        &:first-of-type {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-top: 1px solid #d8d8d8;
        }

        &:last-of-type {
            border-bottom: 1px solid #d8d8d8;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            margin-bottom: 0;
        }

        &-title {
            color: #000;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1.5px;
            margin-bottom: 10px;
        }

        &-text {
            color: #666;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 22px;
            margin-bottom: 10px;
        }

        &-more-wrap {
            color: #2932e1;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0;
        }

        &-more {
            background-color: transparent;
            border: none;
            color: #2932e1;
            cursor: pointer;
            display: inline;
            margin: 0;
            padding: 0;
            text-decoration: none;
        }
    }
}
