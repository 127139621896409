/**
 * @file 首页banner样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-index-banner {
    min-height: 250px;
    margin: 0 auto;
    max-width: 768px;
    padding: 70px 10px 0 20px;
    position: relative;

    &-wrap {
        background-image: url(../../images/banner.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &-title {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.6px;
        line-height: 28px;
        margin-bottom: 6px;
    }

    &-text {
        color: #fff;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 1.4px;
        line-height: 20px;
        margin-bottom: 20px;
        opacity: 0.7;

        @media (min-width: 375px) {
            font-size: 14px;
        }

        @media (min-width: 414px) {
            font-size: 15px;
        }
    }

    &-btn-group {
        bottom: 55px;
        left: 0;
        position: absolute;
        right: 0;
    }

    &-btn {
        border-radius: 999999px;
        color: #1434c2;
        font-size: 14px;
        font-weight: 400;
        height: 30px;
        letter-spacing: 1px;
        margin-left: 20px;
        width: 100px;
    }
}
